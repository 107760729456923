import Link from 'next/link';
import dynamic from 'next/dynamic';

import { IImage } from '../common';
import { FooterWrapper, FooterLogo, Disclaimer } from './style';

const Loader = dynamic(() => import('../../components/Loader'), {
  loading: () => <p>....Loading.....</p>,
});

const Logo = dynamic(() => import('../Logo'), {
  loading: () => <Loader isLoading />,
});

const FooterMenu = dynamic(() => import('./footerMenu').then((mod)=>mod.FooterMenu), {
  loading: () => <Loader isLoading />,
});

export const Footer = () => {
  const data: IImage = {
    url: '/images/past-logo.png',
    title: 'PAST',
    width:196,
    height:38,
  };

  return (
    <FooterWrapper>
      <FooterMenu />

      <FooterLogo>
        <Logo {...data} />
      </FooterLogo>
      <Disclaimer>
        Digital Solution provided by <Link href="https://dat4pro.com">Dat4Pro, Inc.</Link>
      </Disclaimer>
    </FooterWrapper>
  );
};
